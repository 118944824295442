import type { ComponentType } from "react"
import { useEffect, useState } from "react"

export const withDelayedVisibility = (Component): ComponentType => {
    return (props) => {
        const [isVisible, setIsVisible] = useState(false)

        useEffect(() => {
            const timer = setTimeout(() => setIsVisible(true), 2000)
            return () => clearTimeout(timer)
        }, [])

        return (
            <Component
                {...props}
                style={{
                    visibility: isVisible ? "visible" : "hidden",
                    height: "100vh",
                    width: "100vw",
                }}
            />
        )
    }
}
